// 正则校验
// 手机号
const pattern_phone =
  /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
// 手机号和固定电话
const pattern_all_phone =
  /^(([4|8]00[0-9]{7})|(0\d{2,3}-\d{7,8})|([1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}))$/;
// 密码 只能输入8-16个字母、数字、下划线
// const pattern_psd = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{8,16}$/;

// 用户密码(8-16位密码，字母、数字以及特殊字符_!@#$%^&*组成，区分大小写)，至少两种：排除纯数字、纯字母、纯单一特殊字符的即可满足
const pattern_psd =
  /^(?![0-9]+$)(?![a-zA-Z]+$)(?![_]+$)(?![!]+$)(?![@]+$)(?![#]+$)(?![\\$]+$)(?![%]+$)(?![\\^]+$)(?![&]+$)(?![\\*]+$)[a-zA-Z0-9_!@#\\$%\\^&\\*]{8,16}$/;

// 只能输入数字
const pattern_number = /^[0-9]*$/;
// 大小写字母和数字
const pattern_Aznum = /^[A-Za-z0-9]+$/;
// 不能输入特殊字符 即只能输入汉字英文和数字
const pattern_notSpecial = /^[\u4E00-\u9FA5a-zA-Z0-9]+$/;
// 只能输入汉字英文和数字下划线
const pattern_all = /^[\w\u4e00-\u9fa5]+$/;
// 只能字母汉字
const pattern_numValue = /^[\u4E00-\u9FA5a-zA-Z]+$/;
// 邮箱
const pattern_mail = /^[A-Za-zd0-9]+([-_.][A-Za-zd]+)*@([A-Za-zd0-9]+[-.])+[A-Za-zd]{2,5}$/;
// 用户名
// const pattern_user = /^[A-Za-z]{6,}$/;

// 用户登陆名(由6-32位字母、数字、或特殊字符_@组成，须以字母开头且不能以@结尾)
const pattern_user = /^[a-zA-Z][a-zA-Z0-9_@]{4,30}\w$/;

// 用户昵称
const pattern_nickname = /^[a-zA-Z0-9\u4e00-\u9fa5]{1,10}$/;

// 验证码
const pattern_code = /^[0-9]{6,}$/;

export {
  pattern_phone,
  pattern_psd,
  pattern_number,
  pattern_Aznum,
  pattern_notSpecial,
  pattern_all_phone,
  pattern_all,
  pattern_numValue,
  pattern_mail,
  pattern_user,
  pattern_nickname,
  pattern_code
};
