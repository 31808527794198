<template>
  <div>
    <div class="com-input mb-30" :class="{ error: errorMsg }">
      <input
        type="text"
        maxlength="11"
        v-model="phone"
        placeholder="请输入手机号"
        @input="errorMsg = ''"
      />
      <div class="errtip">{{ errorMsg }}</div>
    </div>
    <slide-check
      status="status"
      :startFun="onMpanelStart"
      :successFun="onMpanelSuccess"
      :errorFun="onMpanelError"
    ></slide-check>
    <template v-if="showCode">
      <div class="com-input" :class="{ error: errorCodeMsg }">
        <input
          type="text"
          maxlength="6"
          v-model="code"
          placeholder="请输入验证码"
          @input="errorCodeMsg = ''"
          @keyup.enter="next(showCode)"
        />
        <div class="errtip">{{ errorCodeMsg }}</div>
        <span
          class="input-btn input-btn-send"
          :class="{ disabled: disabledSend }"
          @click="sendCode"
          >{{ btnTest }}</span
        >
      </div>
    </template>
    <button
      class="com-button mt-30"
      :class="[isValid2Next ? 'com-button-primary' : 'com-button-disabled']"
      @click="next(showCode)"
      @keyup.enter="next(showCode)"
    >
      {{ type === 3 ? "下一步" : "登录" }}
    </button>
  </div>
</template>
<script>
import { pattern_phone, pattern_code } from "@/tool/pattern.js";
import { sendCodeMsg } from "@/api/code.js";
export default {
  props: {
    type: {
      type: Number,
      default: 3 // 1 登录  2 注册  3 找回密码
    }
  },
  data() {
    return {
      btnTest: "发送验证码",
      disabledSend: false,
      // inputPhone: true,
      phone: "",
      code: "",
      // sendCodeData: "",
      errorMsg: "",
      errorCodeMsg: "",
      showCode: false,
      timer: null,
      status: "",
      isValid2Next: false
    };
  },
  watch: {
    phone: function (val) {
      this.errorMsg = pattern_phone.test(val) ? "" : "请输入正确的手机号";
      if (!val) {
        this.errorMsg = "";
      }
    },
    code: function (val) {
      this.errorCodeMsg = pattern_code.test(val) ? "" : "验证码为6位数字";
      if (!val) {
        this.errorCodeMsg = "";
      }
      this.isValid2Next = val && !this.errorCodeMsg;
    }
  },
  methods: {
    onMpanelStart() {
      this.checkPhone();
      return !this.errorMsg;
    },
    onMpanelSuccess() {
      this.showCode = true;
      this.$parent.$el.style.height = "310px";
      this.$parent.$el.style.transition = "all 0.5s";
      this.$nextTick(() => {
        this.$parent.$el.style.height = this.$parent.$el.scrollHeight + "px";
      });
    },
    onMpanelError() {
      this.showCode = false;
    },
    // 下一步
    async next() {
      if (!this.isValid2Next) return;
      this.$parent.$el.style.height = "auto";

      this.clearTime();
      this.$emit("confirm", {
        code: this.code,
        mobile: this.phone
      });
    },
    // 校验手机是否为空
    checkPhone() {
      if (!this.phone) {
        this.errorMsg = "请输入手机号";
      }
    },
    // 发送验证码
    async sendCode() {
      if (!this.errorMsg) {
        const res = await sendCodeMsg({
          phone: this.phone,
          type: this.type
        });
        if (!res.success) {
          this.$message.error(res.message);
          if (res.code === 7001001) {
            const secLeft = parseInt(res.data);
            this.countDown(secLeft);
          } else {
            this.clearTime();
          }
          return;
        }
        this.countDown(59);
      }
    },
    countDown(num) {
      this.disabledSend = true;
      this.btnTest = num + "s后重新发送";
      this.timer = setInterval(() => {
        if (num > 0) {
          this.btnTest = num-- + "s后重新发送";
        } else {
          this.clearTime();
        }
      }, 1000);
    },
    clearTime() {
      clearInterval(this.timer);
      this.btnTest = "发送验证码";
      this.disabledSend = false;
    }
  }
};
</script>
<style lang="less" scoped>
.mt-30 {
  margin-top: 30px;
  margin-bottom: 0;
}
.mb-30 {
  margin-bottom: 30px;
}
</style>
