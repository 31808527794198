import request from "tsl-axios";

/**
 * 短信验证码发送
 * @returns {*}
 */
// type 类型[1:登陆,2:注册,3:忘记密码,4:改绑手机]
export function sendCodeMsg(params) {
  return request.post("/captcha/sms/send", params).then((response) => {
    return response;
  });
}
/**
 * 校验短信验证码
 * @returns {*}
 */
export function checkCodeMsg(params) {
  return request.post("/captcha/sms/check", params).then((response) => {
    return response;
  });
}
/**
 * 邮箱验证码发送
 * @returns {*}
 */
// type 类型[4:改绑手机]
export function sendEmailCode(params) {
  return request.post("/captcha/email/send", params).then((response) => {
    return response;
  });
}
