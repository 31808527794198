<template>
  <div style="position: relative">
    <login-content>
      <div class="logincont">
        <div class="title" v-if="finishPhone">设置新密码</div>
        <div class="title" v-else>忘记密码</div>
        <!-- <div class="warning-tip" v-show="serverErrorMsg">
          <span><svg-icon icon-class="pwd-on"></svg-icon></span>
          <span class="warning-text">{{ serverErrorMsg }}</span>
        </div> -->
        <template v-if="finishPhone">
          <div class="com-input" :class="{ error: errorPasswordMsg }">
            <input
              :readonly="readonly"
              type="password"
              maxlength="16"
              v-model="password"
              placeholder="设置密码"
              @blur="isPwdHint = false"
              @focus="
                readonly = false;
                isPwdHint = true;
              "
              @keyup.enter="confirm()"
            />
            <div class="errtip">{{ errorPasswordMsg }}</div>
          </div>
          <div class="com-input" :class="{ error: errorRepeatPasswordMsg }">
            <input
              :readonly="repeatReadonly"
              type="password"
              maxlength="16"
              v-model="repeatPassword"
              placeholder="确认密码"
              @input="errorRepeatPasswordMsg = ''"
              @focus="repeatReadonly = false"
              @keyup.enter="confirm()"
            />
            <div class="errtip">{{ errorRepeatPasswordMsg }}</div>
          </div>
          <button
            class="com-button mar"
            :class="[isValid2Submit ? 'com-button-primary' : 'com-button-disabled']"
            @click="confirm()"
            @keyup.enter="confirm()"
          >
            提交
          </button>
        </template>
        <template v-else>
          <check-phone :type="3" @confirm="confirmPhone"></check-phone>
        </template>
      </div>
    </login-content>
    <popup-hint :top="100" v-show="isPwdHint">
      <template #hint>
        <div class="hint-block">
          <span class="hint-text"><svg-icon icon-class="check"></svg-icon>长度8-16位字符</span>
          <span class="hint-text"
            ><svg-icon icon-class="check"></svg-icon
            >{{ "由字母、数字或字符_!@#$%^&*中至少两种组成（区分大小写）" }}</span
          >
        </div>
      </template>
    </popup-hint>
  </div>
</template>

<script>
import { pattern_psd } from "@/tool/pattern.js";
import CheckPhone from "@/components/sendcode/Index.vue";
import { forgetPwd, forgetPwdSubmit } from "@/api/user.js";
import { _debounce } from "@/tool";
import { mapState } from "vuex";
export default {
  components: { CheckPhone },

  data() {
    this.confirm = _debounce(this.confirmFn, 1000);
    this.confirmPhone = _debounce(this.confirmPhoneFn, 1000);
    return {
      finishPhone: false,
      password: "",
      repeatPassword: "",
      errorPasswordMsg: "",
      errorRepeatPasswordMsg: "",
      userId: "",
      repeatReadonly: false,
      readonly: false,
      isValid2Submit: false,
      isPwdHint: false
      // serverErrorMsg: ""
    };
  },
  computed: mapState({ params: (state) => state.meta.params }),
  watch: {
    password: function (val) {
      this.errorPasswordMsg = pattern_psd.test(val) ? "" : "密码格式有误";
      if (!val) {
        this.errorPasswordMsg = "";
      }
      this.isValid2Submit =
        val && !this.errorPasswordMsg && this.repeatPassword && !this.errorRepeatPasswordMsg;
    },
    repeatPassword: function (val) {
      this.errorRepeatPasswordMsg = val === this.password ? "" : "两次密码不一致";
      if (!val) {
        this.errorRepeatPasswordMsg = "";
      }
      this.isValid2Submit =
        val && !this.errorRepeatPasswordMsg && this.password && !this.errorPasswordMsg;
    }
  },
  methods: {
    async confirmPhoneFn(data) {
      const result = await forgetPwd(data);
      if (result.success) {
        this.userId = result.data;
        this.finishPhone = true;
      } else {
        this.$message.error(result.message);
        // this.serverErrorMsg = result.message;
      }
    },
    // 提交
    async confirmFn() {
      if (!this.isValid2Submit) return;
      const result = await forgetPwdSubmit({
        password: this.password,
        passwordConfirm: this.repeatPassword,
        id: this.userId
      });
      if (result.success) {
        this.$message.success("密码设置成功");
        this.$router.replace(this.$route.meta.inject ? "/inject/login" : "/login");
      } else {
        this.$message.error(result.message);
        // this.serverErrorMsg = result.message;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.mar {
  margin-top: 30px !important;
  margin-bottom: 0 !important;
}
</style>
